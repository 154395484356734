<template>
  <div class="delivery-popup">
    <div ref="popupRef" class="delivery-popup__inner">
      <div class="delivery-popup__close">
        <AppIcon
          icon="close-box"
          :is-raw="true"
          @click="deliveryPopupStore.closePopup()"
        />
      </div>
      <div class="delivery-popup__title">
        <AppIcon
          class="delivery-popup__icon"
          icon="click-n-collect"
          :is-raw="true"
        />
        <p>{{ globalData.title }}</p>
      </div>
      <div class="delivery-popup__info">
        <AtomRichtext :data="globalData" class="delivery-popup__text" />
      </div>

      <div class="delivery-popup__legend">
        <div class="delivery-popup__legend-item available">
          <span class="delivery-popup__legend-bullet" />
          {{ useTranslation('general', 'click_and_collect_1') }}
        </div>
        <div class="delivery-popup__legend-item medium-available">
          <span class="delivery-popup__legend-bullet" />
          {{ useTranslation('general', 'click_and_collect_2') }}
        </div>
        <div class="delivery-popup__legend-item hard-available">
          <span class="delivery-popup__legend-bullet" />
          {{ useTranslation('general', 'click_and_collect_3') }}
        </div>
        <div class="delivery-popup__legend-item not-available">
          <span class="delivery-popup__legend-bullet" />
          {{ useTranslation('general', 'delivery_time_ah') }}
        </div>
        <div class="delivery-popup__legend-item not-available">
          <span class="delivery-popup__legend-bullet" />
          {{ useTranslation('general', 'delivery_time_nl') }}
        </div>
      </div>
      <div class="delivery-popup__stores">
        <div class="delivery-popup__stores-title">
          {{ globalData.title_stores }}
        </div>
        <div class="delivery-popup__stores-item-container">
          <div
            v-for="store in sortedStores"
            :key="store.id"
            class="delivery-popup__stores-item"
            :class="[getdelivery(store)]"
          >
            <div
              class="delivery-popup__bullet"
              :class="[getdelivery(store)]"
            />

            {{ store.store }} {{ store.quantity > 0 ? `(${store.quantity})` : '' }}
          </div>

          <div
            v-for="(store, index) of missingStores"
            :key="store.name + index"
            class="delivery-popup__stores-item"
            :class="[getdelivery(store)]"
          >
            <div
              class="delivery-popup__bullet"
              :class="[getdelivery(store)]"
            />
            {{ store.attributes.name }}  {{ store.attributes.quantity > 0 ? `(${store.attributes.quantity})` : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

/*
    Stores
*/
const deliveryPopupStore = useDeliveryPopupStore();
const globalData = computed(() => {
    const data = getStoryblokSetting('globalcomponents', 'delivery_popup');
    if (!data || !data?.length === 1) return {};

    return data[0];
});

/*
    Stock Data
*/
const product = computed(() => deliveryPopupStore.getProduct);
const cartStore = useCartStore();
const baseLine = computed(() => cartStore.getProductQuantity(product.value) || 0);
const stores = computed(() => product.value?.availableStock || []);
const deliveryTimeKey = computed(() => product.value?.delivery_time?.key);

/* Sort stores by quantity */
const sortedStores = computed(() => stores.value.sort((a, b) => {
    if (a.quantity > b.quantity) return -1;
    if (a.quantity < b.quantity) return 1;
    return 0;
}));

/*
    All stores
*/
const { find } = useStrapi4();
const { data: allStores } = await find('stores', {
    fields: ['id', 'name'],
});

/*
    Missing stores
*/
const missingStores = computed(() => {
    const missing = [];
    allStores.forEach((store) => {
        const found = stores.value.find((s) => s.store === store.attributes.name);
        if (!found) missing.push(store);
    });
    return missing;
});
/*
    Close popup on click outside
*/
const popupRef = ref(null);
onClickOutside(popupRef, () => {
    deliveryPopupStore.closePopup();
});

/*
    Close on route change
*/
const route = useRoute();
watch(() => route.path, () => {
    deliveryPopupStore.closePopup();
});

/*
    Get delivery
*/
const getdelivery = (store) => {
    const { quantity } = store || store.attributes;

    if (quantity > 0 && quantity >= baseLine.value) return 'available';

    switch (deliveryTimeKey.value) {
    case 'delivery_time_0':
    case 'delivery_time_1':
        return 'medium-available';
    case 'delivery_time_2':
        return 'hard-available';
    default:
        return 'not-available';
    }
};

</script>

<style lang="scss" scoped>
.delivery-popup {
    @include z-index('deliveryPopup');

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $C_WHITE_OPACITY_60;

    @include mobile {
        align-items: flex-start;
    }
}

.delivery-popup__inner {
    position: relative;
    display: flex;
    width: calc(100% - var(--page-padding) - var(--page-padding));
    max-width: 836px;
    flex-direction: column;
    padding-top: 77px;
    padding-right: 60px;
    padding-bottom: 60px;
    padding-left: 60px;
    border-radius: 12px;
    background: $C_WHITE;

    @include mobile {
        width: calc(100% - 10px - 10px);
        flex-direction: column;
        padding-top: 31px;
        padding-right: 20px;
        padding-bottom: 34px;
        padding-left: 20px;
        margin-top: 10px;
    }
}

.delivery-popup__title {
    @include typo-size('h2');
    @include typo-font('regular');

    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: $C_GREEN_DARK;

    .delivery-popup__icon {
        width: 37px;
        height: 37px;
        margin-right: 25px;
    }
}

.delivery-popup__info {
    padding-left: 50px;
    margin-bottom: 20px;
}

.delivery-popup__close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: $C_PRIMARY;

    &:hover {
        cursor: pointer;
    }

    svg {
        height: 46px;

        @include mobile {
            height: 36px;
        }
    }

    @include mobile {
        top: 20px;
        right: 20px;
    }
}

.delivery-popup__stores {
    padding-left: 50px;

    .delivery-popup__stores-title {
        @include typo-size('h3');
        @include typo-font('regular');

        margin-bottom: 20px;
        color: $C_GREEN_DARK;
    }

    .delivery-popup__stores-item-container {
        display: flex;
        width: 100%;
        max-height: 134px;
        flex-wrap: wrap;

        @include mobile {
            max-height: unset;
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .delivery-popup__stores-item {
        @include typo-size('p');
        @include typo-font('regular');

        display: flex;
        width: 50%;
        align-items: center;
        margin-bottom: 20px;
        color: $C_PRIMARY;

        &.not-available {
            color: $C_RED_PRIMARY;
        }

        &.medium-available {
            color: $C_GREEN_OLIVE_LIGHT;
        }

        &.hard-available {
            color: $C_ORANGE_STRONG;
        }

        @include mobile {
            margin-right: 0;
        }
    }

    .delivery-popup__bullet {
        width: 14px;
        height: 14px;
        border-radius: 100px;
        margin-right: 20px;
        background: $C_PRIMARY;

        &.not-available {
            background: $C_RED_PRIMARY;
        }

        &.medium-available {
            background: $C_GREEN_OLIVE_LIGHT;
        }

        &.hard-available {
            background: $C_ORANGE_STRONG;
        }
    }
}

.delivery-popup__legend {
    @include fluid('margin-bottom', 20px, 60px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    column-gap: 20px;
    row-gap: 19px;
}
.delivery-popup__legend-bullet {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: $C_PRIMARY;

}
.delivery-popup__legend-item {
    @include typo-font('regular');
    display: flex;
    flex-wrap: nowrap;
    color: $C_PRIMARY;
    column-gap: 20px;
    font-size: 16px;

    &.not-available {
        color: $C_RED_PRIMARY;

        .delivery-popup__legend-bullet {
            background: $C_RED_PRIMARY;
        }
    }

    &.medium-available {
        color: $C_GREEN_OLIVE_LIGHT;

        .delivery-popup__legend-bullet {
            background: $C_GREEN_OLIVE_LIGHT;
        }
    }

    &.hard-available {
        color: $C_ORANGE_STRONG;

        .delivery-popup__legend-bullet {
            background-color: $C_ORANGE_STRONG;
        }
    }
}

</style>
